exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-about-us-page-tsx": () => import("./../../../src/templates/AboutUsPage.tsx" /* webpackChunkName: "component---src-templates-about-us-page-tsx" */),
  "component---src-templates-article-list-tsx": () => import("./../../../src/templates/ArticleList.tsx" /* webpackChunkName: "component---src-templates-article-list-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/Article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-articletype-tsx": () => import("./../../../src/templates/Articletype.tsx" /* webpackChunkName: "component---src-templates-articletype-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/Home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-pedia-list-tsx": () => import("./../../../src/templates/PediaList.tsx" /* webpackChunkName: "component---src-templates-pedia-list-tsx" */),
  "component---src-templates-pedia-tsx": () => import("./../../../src/templates/Pedia.tsx" /* webpackChunkName: "component---src-templates-pedia-tsx" */),
  "component---src-templates-search-tsx": () => import("./../../../src/templates/Search.tsx" /* webpackChunkName: "component---src-templates-search-tsx" */),
  "component---src-templates-service-detail-tsx": () => import("./../../../src/templates/ServiceDetail.tsx" /* webpackChunkName: "component---src-templates-service-detail-tsx" */),
  "component---src-templates-service-list-tsx": () => import("./../../../src/templates/ServiceList.tsx" /* webpackChunkName: "component---src-templates-service-list-tsx" */),
  "component---src-templates-success-cases-tsx": () => import("./../../../src/templates/SuccessCases.tsx" /* webpackChunkName: "component---src-templates-success-cases-tsx" */),
  "component---src-templates-video-list-tsx": () => import("./../../../src/templates/VideoList.tsx" /* webpackChunkName: "component---src-templates-video-list-tsx" */),
  "component---src-templates-video-tsx": () => import("./../../../src/templates/Video.tsx" /* webpackChunkName: "component---src-templates-video-tsx" */)
}

